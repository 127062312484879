import {
  Button,
  Card,
  CardActions,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { green, yellow } from '@material-ui/core/colors';
import { Add, Check, Close, Edit, Warning } from '@material-ui/icons';
import { Club, getClubsStatus, Member } from '@ssf/y2c-helpers';
import { AccountTie } from 'mdi-material-ui';
import React, { useEffect, useState } from 'react';

import {
  BackButton,
  ButtonRow,
  ConfirmDialog,
  Loading,
  RegAndRole,
  RegClubsOtherSchoolClubs,
  RegCreateUpdateClub,
  RegCreateUpdateMember,
  TextBubble,
} from '..';
import { useRegStatus, useUser } from '../../contexts';
import { Registration, Role, useMemdbRequest } from '../../hooks';

export type RegAndRole = Omit<Registration, 'member'> & {
  member: Member;
  roles: Role[];
};

const RegCard: React.FC<{ actions: React.ReactNode }> = ({
  actions,
  children,
}) => (
  <Grid item xs={12} sm={6}>
    <Card css={{ position: 'relative' }}>
      <List
        css={{ height: 314, overflow: 'auto', backgroundColor: 'inherit' }}
        disablePadding
      >
        {children}
      </List>
      <Divider />
      <CardActions css={{ flexDirection: 'row-reverse' }}>
        {actions}
      </CardActions>
    </Card>
  </Grid>
);

function sortRegs(regs: RegAndRole[]) {
  return regs.sort((a, b) =>
    a.member.lastname.toUpperCase() > b.member.lastname.toUpperCase() ? 1 : -1,
  );
}

const ClubStatus: React.FC<{ club: Club }> = ({ club }) => {
  const { status } = useRegStatus();
  const { clubsStatus } = getClubsStatus([club], status.school, [
    {
      id: -1,
      name: 'fake',
      groupid: -1,
      clubid: club.id,
      roles: [],
      players: [],
    },
  ]);

  return (
    <Tooltip
      key={club.id}
      placement="top"
      title={
        clubsStatus.message ||
        'Den här klubben är godkänd att delta i tävlingen'
      }
    >
      {clubsStatus.status !== 3 ? (
        <Warning fontSize="small" css={{ color: yellow[800] }} />
      ) : (
        <Check fontSize="small" css={{ color: green[800] }} />
      )}
    </Tooltip>
  );
};

const RoleIcon: React.FC<{ roles: RegAndRole['roles'] }> = ({ roles }) =>
  roles.length ? (
    <Tooltip
      key={roles[0].memberid}
      placement="top"
      css={{ position: 'relative' }}
      title={
        roles[0].orgrole === 100
          ? 'Ordförande'
          : roles[0].orgrole === 101
          ? 'Sekreterare'
          : roles[0].orgrole === 108
          ? 'Revisor'
          : 'Ledamot'
      }
    >
      <AccountTie fontSize="small" color="action" />
    </Tooltip>
  ) : null;

export function RegClubs({ buttons }: { buttons: React.ReactNode }) {
  const { user } = useUser();
  const { status, setStatus, page, setPage } = useRegStatus();
  const clubs = status.clubs || [];
  const { spacing } = useTheme<Theme>();
  const [club, setClub] = useState<Club | undefined>(clubs[0]);
  const [selectedReg, setReg] = useState<RegAndRole>();
  const [currentRegs, setCurrentRegs] = useState<RegAndRole[]>([]);
  const [oldRegs, setOldRegs] = useState<RegAndRole[]>([]);
  const { request, isLoading } = useMemdbRequest();

  const [confirmRemoval, setConfirmRemoval] = useState(false);

  useEffect(() => {
    if (club) {
      request<{ registrations: Registration[]; roles: Role[] }>({
        url: `/club/${club.id}`,
        params: {
          schema: {
            registrations: [
              {
                member: {
                  id: 1,
                  firstname: 1,
                  lastname: 1,
                  birthday: 1,
                  sex: 1,
                  street: 1,
                  zipcode: 1,
                  city: 1,
                  email: 1,
                  phone: 1,
                },
                regstart: 1,
                regend: 1,
                date: 1,
                membershiptype: 1,
                fee: 1,
                filter: `(regend>=${new Date().getFullYear() - 1}-01-01)`,
              },
            ],
            roles: [
              {
                orgrole: 1,
                active: 1,
                orgid: 1,
                orgtype: 1,
                memberid: 1,
                start: 1,
                seclevel: 1,
                end: 1,
                filter: '(&(end=1970-01-01)(orgrole<=102))',
              },
            ],
          },
        },
      }).then((response) => {
        if (response) {
          const regEndConditionDate = `${new Date().getFullYear()}-01-01`;

          const regsObj = response.data.registrations.reduce<{
            [key: number]: RegAndRole;
          }>((obj, reg) => {
            const oldReg = obj[reg.member.id];
            if (!oldReg || oldReg.regend < reg.regend) {
              // eslint-disable-next-line no-param-reassign
              obj[reg.member.id] = { ...reg, roles: [] };
            }
            return obj;
          }, {});

          response.data.roles.forEach(
            (role) =>
              regsObj[role.memberid] && regsObj[role.memberid].roles.push(role),
          );

          const localAllRegs = sortRegs(Object.values(regsObj)).reduce<
            [RegAndRole[], RegAndRole[]]
          >(
            ([regs, prevRegs], reg) => {
              if (reg.regend >= regEndConditionDate) {
                regs.push(reg);
              } else {
                prevRegs.push(reg);
              }

              return [regs, prevRegs];
            },
            [[], []],
          );

          setCurrentRegs(localAllRegs[0]);
          setOldRegs(localAllRegs[1]);
        } else {
          setCurrentRegs([]);
          setOldRegs([]);
        }
      });
    }
  }, [club]);

  const viewButtons = (
    <BackButton
      onBack={() => (clubs.length ? setPage(['clubs']) : setPage(['overview']))}
      css={{ marginRight: 'auto' }}
    />
  );

  function handleNewEditedClub(_club: Club) {
    if (club) {
      clubs[clubs.indexOf(club)] = _club;
      setStatus({ clubs: [...clubs] });
    } else {
      setStatus({ clubs: [...clubs, _club] });
    }
    setPage(['clubs']);
    setClub(_club);
  }

  function handleNewEditedReg(reg: RegAndRole) {
    if (selectedReg) {
      const index = currentRegs.indexOf(selectedReg);
      currentRegs[index] = reg;
      setCurrentRegs(sortRegs([...currentRegs]));
    } else {
      setCurrentRegs(sortRegs([...currentRegs, reg]));
    }
    setPage(['clubs']);
    if (club && club.registrations && club.roles) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { roles, ...clubReg } = reg; // clubs don't want roles, error when submiting verification
      club.registrations[
        selectedReg
          ? club.registrations.findIndex(
              ({ member }) => member.id === reg.member.id,
            )
          : club.registrations.length
      ] = clubReg;

      if (selectedReg) {
        club.roles = club.roles.filter(
          (role) =>
            !(
              role.memberid === selectedReg.member.id &&
              selectedReg.roles.some(({ orgrole }) => role.orgrole === orgrole)
            ),
        );
      }
      club.roles = [...club.roles, ...roles];
    }
    setStatus({ clubs: [...clubs] });
  }

  const handleAddMembership = (reg: RegAndRole) => async () => {
    if (club) {
      const response = await request<{ result: number }>({
        url: `/club/${club.id}/membership/${reg.member.id}`,
        method: 'POST',
      });

      if (response && response.data && response.data.result > 0) {
        const newReg = {
          ...reg,
          membershiptype: 130,
          fee: 10,
          date: new Date().toLocaleDateString('sv-SE'),
          regstart: `${new Date().getFullYear()}-01-01`,
          regend: `${new Date().getFullYear()}-12-31`,
        };

        oldRegs.splice(oldRegs.indexOf(reg), 1);
        setOldRegs(sortRegs([...oldRegs]));
        setCurrentRegs(sortRegs([...currentRegs, newReg]));

        if (club.registrations) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { roles, ...clubReg } = newReg; // clubs don't want roles, error when submiting verification
          club.registrations.push(clubReg);
          setStatus({ clubs: [...clubs] });
        }
      }
    }
  };

  const handleRemoveMembership = async () => {
    if (club && selectedReg) {
      const {
        member: { id },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        roles,
        ...reg
      } = selectedReg;
      const response = await request<{ result: number }>({
        method: 'DELETE',
        url: `/club/${club.id}/membership`,
        params: {
          reg: { ...reg, memberid: id, clubid: club.id },
        },
      });

      if (response && response.data && club.registrations) {
        club.registrations.splice(
          club.registrations.findIndex(({ member }) => member.id === id),
          1,
        );
        setStatus({ clubs: [...clubs] });
        currentRegs.splice(currentRegs.indexOf(selectedReg), 1);
        setCurrentRegs([...currentRegs]);

        setConfirmRemoval(false);
      }
    }
  };

  return !page[1] ? (
    <>
      <TextBubble>
        Välj klubb till vänster och hantera medlemmarna i vald klubb till höger.
        Klicka på <i>"NY KLUBB"</i> för att skapa fler klubbar eller klicka på{' '}
        <i>"NY MEDLEM"</i> för att lägga till fler medlemmar i vald klubb.
        Ändringarna sparas automatiskt.
      </TextBubble>
      <Grid container spacing={2}>
        <RegCard
          actions={
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setClub(undefined);
                setPage(['clubs', 'club']);
              }}
            >
              Ny klubb
            </Button>
          }
        >
          <ListSubheader css={{ backgroundColor: 'inherit' }}>
            Mina klubbar
          </ListSubheader>
          {clubs.map((_club) => (
            <ListItem
              key={_club.id}
              button
              dense
              onClick={() => setClub(_club)}
              selected={_club === club}
            >
              <ListItemText primary={_club.name} />
              <ClubStatus club={_club} />
              {_club.active < 0 &&
                _club.startdate &&
                +_club.startdate.substr(0, 4) === new Date().getFullYear() && (
                  <ListItemSecondaryAction>
                    <Tooltip
                      key={_club.id}
                      placement="top"
                      title={`Ändra namn på ${_club.name}`}
                      aria-label="edit"
                    >
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        size="small"
                        onClick={() => {
                          setClub(_club);
                          setPage(['clubs', 'club']);
                        }}
                      >
                        <Edit fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
            </ListItem>
          ))}

          {!!status.school && (
            <RegClubsOtherSchoolClubs school={status.school} clubs={clubs} />
          )}
        </RegCard>
        <RegCard
          actions={
            <>
              <Button
                color="primary"
                variant="contained"
                css={{ whiteSpace: 'nowrap' }}
                onClick={() => {
                  setReg(undefined);
                  setPage(['clubs', 'member']);
                }}
              >
                Ny medlem
              </Button>
              {/* <Button color="primary" css={{ whiteSpace: 'nowrap' }} disabled>
                Importer medlemar
              </Button> */}
              {isLoading && <Loading />}
            </>
          }
        >
          {clubs.length && !isLoading && (
            <>
              <ListSubheader css={{ backgroundColor: 'inherit' }}>
                Nuvarande medlemmar
              </ListSubheader>
              {!currentRegs.length && (
                <ListItem dense>
                  <ListItemText css={{ fontStyle: 'italic' }}>
                    Inga medlemmar finns för innevarande år
                  </ListItemText>
                </ListItem>
              )}
              {currentRegs.map((reg) => (
                <ListItem
                  key={reg.member.id}
                  button
                  dense
                  onClick={() => {
                    setReg(reg);
                    setPage(['clubs', 'member']);
                  }}
                >
                  <ListItemText
                    primary={`${reg.member.firstname} ${reg.member.lastname}`}
                  />
                  <RoleIcon roles={reg.roles} />
                  {reg.membershiptype >= 120 &&
                    reg.fee > 0 &&
                    user &&
                    reg.member.id !== user.id && (
                      <ListItemSecondaryAction>
                        <Tooltip
                          key={reg.member.id}
                          placement="top"
                          css={{ position: 'relative' }}
                          title={`Ta bort medlemskapet för ${reg.member.firstname} ${reg.member.lastname}`}
                          aria-label="remove"
                        >
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                              setReg(reg);
                              setConfirmRemoval(true);
                            }}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    )}
                </ListItem>
              ))}
              {!!oldRegs.length && (
                <>
                  <ListSubheader css={{ backgroundColor: 'inherit' }}>
                    Tidigare medlemmar
                  </ListSubheader>
                  {oldRegs.map((reg) => (
                    <ListItem
                      key={reg.member.id}
                      button
                      dense
                      onClick={handleAddMembership(reg)}
                    >
                      <ListItemText
                        primary={`${reg.member.firstname} ${reg.member.lastname}`}
                      />
                      <RoleIcon roles={reg.roles} />
                      <ListItemSecondaryAction>
                        <Tooltip
                          key={reg.member.id}
                          placement="top"
                          css={{ position: 'relative' }}
                          title={`Lägg ${reg.member.firstname} ${reg.member.lastname} till nuvarande medlemmar`}
                          aria-label="add"
                        >
                          <IconButton
                            edge="end"
                            aria-label="add"
                            size="small"
                            onClick={handleAddMembership(reg)}
                          >
                            <Add fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </>
              )}
            </>
          )}
        </RegCard>
      </Grid>

      <ButtonRow css={{ margin: `${spacing(1)}px 0` }}>{buttons}</ButtonRow>
      <ConfirmDialog
        open={confirmRemoval}
        button={
          <Button autoFocus color="primary" onClick={handleRemoveMembership}>
            Ta bort
          </Button>
        }
        onClose={() => setConfirmRemoval(false)}
      >
        <Typography>
          Är du säker på att du vill ta bort medlemskapet för{' '}
          {selectedReg
            ? `${selectedReg.member.firstname} ${selectedReg.member.lastname}`
            : ''}
          ?
        </Typography>
      </ConfirmDialog>
    </>
  ) : (page[1] === 'club' || !club) && status.school ? (
    <RegCreateUpdateClub
      club={club}
      buttons={viewButtons}
      onNewEditedClub={handleNewEditedClub}
    />
  ) : club ? (
    <RegCreateUpdateMember
      club={club}
      buttons={viewButtons}
      reg={selectedReg}
      onNewEditedReg={handleNewEditedReg}
    />
  ) : null;
}
